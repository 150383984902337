import Link from 'next/link'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { checkMinimumOrderValue, mapByVendor } from 'services/cart'
import { formatPriceString } from 'services/price'
import { Vendor } from 'etc/strapi-types'
import { Col, Row, Typography } from 'antd'
import styles from './minimum-order-value-alert.module.less'

const { Text } = Typography

interface Props {
	vendors: Pick<Vendor, 'slug' | 'name' | 'minimumOrderValue'>[]
}

const MinimumOrderValueAlert = ({ vendors }: Props) => {
	const items = useSelector((state: RootState) => state.cart.items)

	const sorted = mapByVendor(items)

	return (
		<div>
			{Object.entries(sorted).map(([vendor, items]) => {
				const minimumOrderValue = vendors.find(v => v.slug === vendor)?.minimumOrderValue ?? 0
				const { minimumReached, total } = checkMinimumOrderValue(minimumOrderValue, items)

				if (!minimumReached) {
					const name = vendors.find(({ slug }) => slug === vendor)?.name

					const difference = minimumOrderValue - total

					return (
						<div key={vendor} className={styles.alert}>
							<Text className='grey-9'>Es fehlen noch </Text>
							<Text className={styles.red}>{formatPriceString(difference)}</Text>
							<Text className='grey-9'> zum Mindestbestellwert für </Text>
							<Text className='grey-9'>{name}.</Text>
							<Row gutter={16}>
								<Col>
									<Link href={`/${vendor}`}>
										<a>
											<Text className='regular-12 grey-7 link'>Zu gültigen Produkten</Text>
										</a>
									</Link>
								</Col>
								<Col>
									<Link href='/faq'>
										<a>
											<Text className='regular-12 grey-7 link'>Mehr Informationen</Text>
										</a>
									</Link>
								</Col>
							</Row>
						</div>
					)
				}

				return null
			})}
		</div>
	)
}

export default MinimumOrderValueAlert
