import { useState } from 'react'
import Link from 'next/link'
import { Button, Checkbox, Col, Grid, Input, Row, Typography } from 'antd'
import BackgroundWrapper from 'components/background-wrapper'
import styles from './newsletter-signup.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	light?: boolean
	size?: 'small' | 'medium'
}

const NewsletterSignup = ({ light, size = 'medium' }: Props) => {
	const [email, setEmail] = useState<string>()
	const [checked, setChecked] = useState(true)
	const [done, setDone] = useState(false)

	const screens = useBreakpoint()

	const signup = async () => {
		if (checked && email?.length) {
			await fetch('/api/mailchimp-signup', {
				method: 'POST',
				body: JSON.stringify({ email })
			})
			setEmail(undefined)
			setDone(true)
		}
	}

	return (
		<BackgroundWrapper color={light ? '#f5f5f5' : '#262626'} className={styles[size]}>
			<Row justify='center' align='middle' gutter={32}>
				{screens.sm && (
					<Col>
						<img
							src={
								done
									? `/images/icons/mailbox-new${light ? '-dark' : ''}.svg`
									: `/images/icons/mailbox${light ? '-dark' : ''}.svg`
							}
							width={size === 'medium' ? 128 : 112}
							height={size === 'medium' ? 128 : 112}
						/>
					</Col>
				)}
				<Col className={styles.col} style={{ textAlign: screens.xs ? 'center' : 'left' }}>
					<div className={styles.title}>
						<Text className={`semibold-24 ${light ? 'grey-9' : 'grey-2'}`}>
							Bleib dabei und sei einer von uns!
						</Text>
					</div>
					{size === 'medium' && (
						<div>
							<Text className='regular-16 grey-3'>
								Wir informieren dich nur über die wichtigsten Neuigkeiten.
							</Text>
						</div>
					)}
					<Row justify='space-between' align='middle' gutter={16} className={styles.signup}>
						<Col flex={1}>
							<Input
								type='email'
								placeholder='max@mustermann.de'
								value={email}
								onChange={e => setEmail(e.target.value)}
								size={screens.xs ? 'middle' : 'large'}
							/>
						</Col>
						<Col>
							<Button
								type='primary'
								size={screens.xs ? 'middle' : 'large'}
								onClick={signup}
								disabled={!checked}
							>
								Absenden
							</Button>
						</Col>
					</Row>
					<Checkbox
						onChange={e => setChecked(e.target.checked)}
						checked={checked}
						className={styles.checkbox}
					>
						<Text className={light ? 'grey-8' : 'grey-2'}>Ich stimme den </Text>
						<Link href='/datenschutz'>
							<a target='_blank'>
								<Text className={light ? 'grey-8 link' : 'grey-2 link'}>
									Datenschutzbedingungen
								</Text>
							</a>
						</Link>
						<Text className={light ? 'grey-8' : 'grey-2'}> zu.</Text>
					</Checkbox>
				</Col>
			</Row>
		</BackgroundWrapper>
	)
}

export default NewsletterSignup
