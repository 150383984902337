import { Vendor } from 'etc/strapi-types'
import { CartItem } from 'store/cart/types'

export const checkMinimumOrderValue = (minimumOrderValue: number, items: CartItem[]) => {
	const onlyCouponsIncluded = items.filter(item => !item.is_coupon).length === 0

	const total = items.reduce((a, c) => {
		if (c.is_coupon) return a
		return a + c.quantity * parseFloat(c.variant.priceV2.amount)
	}, 0)

	const minimumReached = total >= minimumOrderValue || onlyCouponsIncluded

	return {
		minimumOrderValue,
		minimumReached,
		total
	}
}

export const allMinimumsReached = (
	items: { [id: string]: CartItem },
	vendors: Pick<Vendor, 'slug' | 'minimumOrderValue'>[]
) => {
	const sorted = mapByVendor(items)

	for (let [vendor, items] of Object.entries(sorted)) {
		const minimumOrderValue = vendors.find(v => v.slug === vendor)?.minimumOrderValue ?? 0
		const { minimumReached } = checkMinimumOrderValue(minimumOrderValue, items)
		if (!minimumReached) return false
	}

	return true
}

export const sortByVendor = (items: { [id: string]: CartItem }) => {
	return Object.values(items)
		.filter(item => !item.is_coupon)
		.sort((a, b) => (a.vendor > b.vendor ? 1 : -1))
}

export const mapByVendor = (items: { [id: string]: CartItem }) => {
	return Object.values(items).reduce<{ [vendor: string]: CartItem[] }>((a, c) => {
		const i = a[c.vendor] ?? []
		return {
			...a,
			[c.vendor]: [...i, c]
		}
	}, {})
}

export const containsAlcohol = (items: { [id: string]: CartItem }) => {
	for (let item of Object.values(items)) {
		if (item.is_alcohol) return true
	}
	return false
}
