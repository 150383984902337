import dayjs from 'dayjs'
import 'dayjs/locale/de'
import { Metafield } from 'etc/storefront-types'

dayjs.locale('de')

export const getEstimatedDelivery = (dates: Array<{ days?: string; weeks?: string }>) => {
	let days = 0

	for (let date of dates) {
		if (date.days) {
			let value = 0

			if (date.days.includes('-')) {
				value = parseInt(date.days.split('-')[1])
			} else {
				value = parseInt(date.days)
			}

			if (value > days) {
				days = value
			}
		} else if (date.weeks) {
			let value = 0

			if (date.weeks.includes('-')) {
				value = parseInt(date.weeks.split('-')[1]) * 7
			} else {
				value = parseInt(date.weeks) * 7
			}

			if (value > days) {
				days = value
			}
		}
	}

	days = Math.ceil((days / 5) * 7)

	let date = dayjs().add(days, 'd')

	if (date.day() === 6) {
		date = date.add(1, 'd')
	}

	return date.format('dd, DD.MM.YYYY')
}

export const getProductEstimatedDelivery = (metafields: Array<Metafield | null>) => {
	return {
		days: metafields.find(metafield => metafield?.key === 'estimated_delivery_days')?.value,
		weeks: metafields.find(metafield => metafield?.key === 'estimated_delivery_weeks')?.value
	}
}
