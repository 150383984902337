import { useState } from 'react'
import Link from 'next/link'
import { Category, getCategories } from 'services/categories'
import { Button, Col, Grid, Row, Typography } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import SubMenu from './sub-menu'
import SearchBox from './search-box'
import styles from './navigation.module.less'
import MobileMenu from './mobile-menu'

const { useBreakpoint } = Grid
const { Text } = Typography

const Navigation = () => {
	const [activeCategory, setActiveCategory] = useState<Category>()
	const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

	const screen = useBreakpoint()

	const categories = getCategories()

	return (
		<nav>
			<Row justify='space-between' align='middle' className={styles.container}>
				<Col onMouseLeave={() => setActiveCategory(undefined)} span={0} md={22} lg={17}>
					<Row justify='space-between'>
						{categories.map(item => (
							<Col
								key={item.handle}
								className={item.handle === 'themenwelten' ? styles.themenwelten : undefined}
							>
								<Link href={`/kategorie/${item.handle}`}>
									<a className={styles.item}>
										<div
											onMouseEnter={() => setActiveCategory(item.items?.length ? item : undefined)}
										>
											<Row align='middle'>
												{item.icon && <img src={item.icon.url} width={28} height={28} />}
												<Text
													className={
														item.handle === 'themenwelten'
															? styles.categoryThemenwelten
															: styles.category
													}
												>
													{item.name}
												</Text>
											</Row>
										</div>
									</a>
								</Link>
							</Col>
						))}
					</Row>
				</Col>
				{!screen.md && (
					<Col>
						<Button
							onClick={() => setMobileMenuVisible(true)}
							icon={<MenuOutlined />}
							type='link'
							className={styles.burger}
						/>
					</Col>
				)}
				{(screen.lg || !screen.md) && (
					<Col flex={1} className={styles.searchbox}>
						<Row justify='end'>
							<SearchBox />
						</Row>
					</Col>
				)}
			</Row>
			<SubMenu category={activeCategory} />
			{!screen.md && (
				<MobileMenu visible={mobileMenuVisible} hide={() => setMobileMenuVisible(false)} />
			)}
		</nav>
	)
}

export default Navigation
