import Link from 'next/link'
import { Col, Grid, Row, Typography } from 'antd'
import {
	FacebookOutlined,
	HomeOutlined,
	InstagramOutlined,
	MailOutlined,
	PhoneOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'
import Spacer from 'components/spacer'
import NewsletterSignup from './newsletter-signup'
import Values from './values'
import styles from './footer.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface NavLinkProps {
	href: string
	text: string
}

const NavLink = ({ href, text }: NavLinkProps) => (
	<div className={styles.link}>
		<Link href={href}>
			<a>
				<Text className='regular-16 grey-6'>{text}</Text>
			</a>
		</Link>
	</div>
)

interface Props {
	slim?: boolean
}

const Footer = ({ slim }: Props) => {
	const screen = useBreakpoint()

	return (
		<footer className={styles.container}>
			{!slim && (
				<>
					<Values />
					<NewsletterSignup />
				</>
			)}
			<div className={styles.content}>
				<Spacer size={48} />
				<Row gutter={32} justify={!screen.sm ? 'center' : 'start'}>
					<Col span={24} sm={10} md={10} lg={6}>
						<div className={!screen.sm ? 'align-center' : undefined}>
							<img src='/edomo-logo-dark.svg' width={210} height={100} />
						</div>
						<Spacer size={12} />
						<Row justify={!screen.sm ? 'center' : 'start'} className={styles.textRow}>
							<Col>
								<div className={styles.line}>
									<PhoneOutlined />
								</div>
								<div className={styles.line}>
									<MailOutlined />
								</div>
								<div className={styles.line}>
									<HomeOutlined />
								</div>
							</Col>
							<Col>
								<div className={styles.line}>
									<Text className='regular-16 grey-1'>05551 5894318</Text>
								</div>
								<div className={styles.line}>
									<Text className='regular-16 grey-1'>info@edomo.de</Text>
								</div>
								<div className={styles.line}>
									<Text className='regular-16 grey-1'>
										Edomo GmbH
										<br />
										Brunkelskamp 11
										<br />
										37154 Northeim
									</Text>
								</div>
							</Col>
						</Row>
						{screen.lg ? <div className={styles.separator} /> : <Spacer size={48} />}
					</Col>
					<Col span={24} sm={6} md={5} lg={3}>
						<div className={!screen.sm ? 'align-center' : undefined}>
							<Text className='semibold-20 secondary-color'>Seiten</Text>
							{[
								{ text: 'Startseite', href: '/' },
								{ text: 'Über uns', href: '/ueber-uns' },
								{ text: 'Kontakt', href: '/kontakt' }
							].map((link, index) => (
								<NavLink key={index} href={link.href} text={link.text} />
							))}
						</div>
						{!screen.sm && <Spacer size={32} />}
					</Col>
					<Col span={24} sm={8} md={5} lg={4}>
						<div className={!screen.sm ? 'align-center' : undefined}>
							<Text className='semibold-20 secondary-color'>Informationen</Text>
							{[
								{ text: 'Häufige Fragen', href: '/faq' },
								{ text: 'Versand & Lieferung', href: '/faq/versand-und-lieferung' },
								{ text: 'Über Edomo', href: '/faq/ueber-edomo' },
								{ text: 'Zahlung', href: '/faq/zahlung' },
								{ text: 'Rücksendung', href: '/faq/ruecksendung-und-rueckerstattung' },
								{ text: 'Rückerstattung', href: '/faq/ruecksendung-und-rueckerstattung' }
							].map((link, index) => (
								<NavLink key={index} href={link.href} text={link.text} />
							))}
							<div className={styles.link}>
								<a href='#' className='iubenda-cs-preferences-link'>
									<Text className='regular-16 grey-6'>Cookie-Einstellungen</Text>
								</a>
							</div>
						</div>
						{!screen.sm && <Spacer size={32} />}
					</Col>
					<Col span={24} sm={8} md={5} lg={5}>
						<div className={!screen.sm ? 'align-center' : undefined}>
							<Text className='semibold-20 secondary-color'>Rechtstexte</Text>
							{[
								{ text: 'Widerrufsbelehrung', href: '/widerruf' },
								{ text: 'AGB', href: '/agb' },
								{ text: 'Datenschutz', href: '/datenschutz' },
								{ text: 'Impressum', href: '/impressum' }
							].map((link, index) => (
								<NavLink key={index} href={link.href} text={link.text} />
							))}
							{screen.lg && <div className={styles.separator} />}
						</div>
						{!screen.sm && <Spacer size={32} />}
					</Col>
					<Col span={24} sm={16} lg={6}>
						{!screen.lg && screen.md && <Spacer size={48} />}
						<Row gutter={32}>
							<Col span={24} sm={18} md={12} lg={24}>
								{!screen.sm && <Spacer size={24} />}
								<Text className={`semibold-16 grey-1 `}>Zahlungsmöglichkeiten</Text>
								<Spacer size={16} />
								<img src='/images/payments.svg' width='100%' height='auto' />
								<Spacer size={16} />
							</Col>
							<Col span={24} sm={0} md={12} lg={24}>
								{!screen.sm && <Spacer size={24} />}
								<Text className={`semibold-16 grey-1 `}>Unsere Vorteile</Text>
								<Row className={styles.textRow}>
									<Col>
										<div className={styles.line}>
											<img src='/images/icons/free-shipping-light.svg' width={24} height={24} />
										</div>
										<div className={styles.line}>
											<img src='/images/icons/map-light.svg' width={24} height={24} />
										</div>
										<div className={styles.line}>
											<img src='/images/icons/payment-light.svg' width={24} height={24} />
										</div>
									</Col>
									<Col flex={1}>
										<div className={styles.line}>
											<Text className='regular-16 grey-6'>Kostenloser Versand</Text>
										</div>
										<div className={styles.line}>
											<Text className='regular-16 grey-6'>Versand deutschlandweit</Text>
										</div>
										<div className={styles.line}>
											<Text className='regular-16 grey-6'>Sichere Zahlung</Text>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Spacer size={48} />
				<Row justify='center' align='middle' className={styles.social}>
					<Text className='regular-16 grey-2'>Folge uns:</Text>
					<Link href='https://www.facebook.com/edomo_de'>
						<a target='blank'>
							<FacebookOutlined className={styles.socialIcon} />
						</a>
					</Link>
					<Link href='https://www.instagram.com/edomo.de/'>
						<a target='blank'>
							<InstagramOutlined className={styles.socialIcon} />
						</a>
					</Link>
				</Row>
				<Spacer size={16} />
				<Row justify='center'>
					<Text className='regular-12 grey-7'>© {dayjs().year()} Edomo - All rights reserved.</Text>
				</Row>
			</div>
		</footer>
	)
}

export default Footer
