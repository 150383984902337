import { useRouter } from 'next/router'
import { AutoComplete, Grid, Input } from 'antd'

const { useBreakpoint } = Grid

const SearchBox = () => {
	const router = useRouter()

	const screen = useBreakpoint()

	return (
		<AutoComplete>
			<Input.Search
				onSearch={value => {
					const query = value.trim()
					if (query.length > 0) router.push(`/suche/${query}`)
				}}
				size={screen.md ? 'middle' : 'large'}
				style={screen.md ? undefined : { borderRadius: 0 }}
			/>
		</AutoComplete>
	)
}

export default SearchBox
