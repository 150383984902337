import { client } from 'services/storefront'
import { AppThunk } from 'store/store'
import {
	CheckoutCreatePayload,
	CheckoutLineItemInput,
	CheckoutLineItemsReplacePayload
} from 'etc/storefront-types'
import {
	APPLY_DISCOUNT_CODE,
	CREATE_CHECKOUT,
	REMOVE_DISCOUNT_CODE,
	UPDATE_CHECKOUT
} from 'etc/queries'
import {
	CartActions,
	CartItem,
	PartialCheckout,
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_EDIT_QUANTITY,
	CART_CREATE_CHECKOUT_REQUEST,
	CART_CREATE_CHECKOUT_SUCCESS,
	CART_UPDATE_CHECKOUT,
	CART_EDIT_NOTE,
	CART_CLEAR,
	CART_APPLY_DISCOUNT,
	CART_REMOVE_DISCOUNT
} from './types'

export const addItem = (item: CartItem): CartActions => {
	if (typeof window !== undefined && window.gtag) {
		window.gtag('event', 'add_to_cart', {
			items: [
				{
					id: item.handle,
					name: item.title,
					brand: item.vendor,
					quantity: item.quantity,
					price: item.variant.priceV2
				}
			]
		})
	}

	return {
		type: CART_ADD_ITEM,
		item,
		timestamp: Date.now()
	}
}

export const removeItem = (id: string): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { items }
		} = getState()

		if (typeof window !== undefined && window.gtag) {
			window.gtag('event', 'remove_from_cart', {
				items: [
					{
						id: items[id].handle,
						name: items[id].title,
						brand: items[id].vendor,
						quantity: items[id].quantity,
						price: items[id].variant.priceV2
					}
				]
			})
		}

		dispatch({ type: CART_REMOVE_ITEM, id })
	}
}

export const editQuantity = (id: string, quantity: number): CartActions => {
	return {
		type: CART_EDIT_QUANTITY,
		id,
		quantity
	}
}

const getLineItems = (items: { [id: string]: CartItem }): CheckoutLineItemInput[] => {
	return Object.values(items).map(item => {
		return {
			variantId: item.id,
			quantity: item.quantity,
			...(item.note && { customAttributes: [{ key: 'note', value: item.note }] })
		}
	})
}

export const createCheckout = (): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({ type: CART_CREATE_CHECKOUT_REQUEST })

		const {
			cart: { items }
		} = getState()

		const lineItems = getLineItems(items)

		const { data } = await client.mutate<{ checkoutCreate: Partial<CheckoutCreatePayload> }>({
			mutation: CREATE_CHECKOUT,
			variables: { lineItems }
		})

		if (data?.checkoutCreate.checkout) {
			dispatch({ type: CART_CREATE_CHECKOUT_SUCCESS, checkout: data.checkoutCreate.checkout })
		} else {
			throw Error('unable to create checkout')
		}
	}
}

export const updateCheckout = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { items, checkout }
		} = getState()

		if (!checkout?.id) return

		const lineItems = getLineItems(items)

		try {
			const { data } = await client.mutate<{
				checkoutLineItemsReplace: Partial<CheckoutLineItemsReplacePayload>
			}>({
				mutation: UPDATE_CHECKOUT,
				variables: {
					lineItems,
					checkoutId: checkout.id
				}
			})

			if (data?.checkoutLineItemsReplace.checkout) {
				dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.checkoutLineItemsReplace.checkout })
			}
		} catch (err: any) {
			if (err.message === 'Checkout is already completed.') {
				dispatch(clearCart())
			}
		}
	}
}

export const applyDiscount = (code: string): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { checkout }
		} = getState()

		if (!checkout?.id) return

		const { data } = await client.mutate<{
			checkoutDiscountCodeApplyV2: { checkout: PartialCheckout }
		}>({
			mutation: APPLY_DISCOUNT_CODE,
			variables: { discountCode: code, checkoutId: checkout.id }
		})

		if (data?.checkoutDiscountCodeApplyV2.checkout) {
			dispatch({ type: CART_APPLY_DISCOUNT, code })
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data.checkoutDiscountCodeApplyV2.checkout })
		}
	}
}

export const removeDiscount = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			cart: { checkout }
		} = getState()

		if (!checkout?.id) return

		const { data } = await client.mutate<{
			checkoutDiscountCodeRemove: { checkout: PartialCheckout }
		}>({
			mutation: REMOVE_DISCOUNT_CODE,
			variables: { checkoutId: checkout.id }
		})

		if (data?.checkoutDiscountCodeRemove.checkout) {
			dispatch({ type: CART_REMOVE_DISCOUNT })
			dispatch({ type: CART_UPDATE_CHECKOUT, checkout: data?.checkoutDiscountCodeRemove.checkout })
		}
	}
}

export const editNote = (id: string, note?: string): CartActions => {
	return {
		type: CART_EDIT_NOTE,
		id,
		note
	}
}

export const clearCart = () => {
	return {
		type: CART_CLEAR
	}
}
