import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import Head from 'next/head'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { Badge, Col, Grid, Row, Typography } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import CartWidget from 'components/cart/cart-widget'
import BackgroundWrapper from 'components/background-wrapper'
import Spacer from 'components/spacer'
import Navigation from './navigation'
import SearchBox from './search-box'
import useCartNotification from './useCartNotification'
import styles from './header.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	title?: string
}

const Header = ({ title }: Props) => {
	const items = useSelector((state: RootState) => state.cart.items)

	const [headerVisible, setHeaderVisible] = useState(true)
	const [cartWidgetVisible, setCartWidgetVisible] = useState(false)

	const scrollY = useRef<number>()

	const screen = useBreakpoint()

	const noOfItems = Object.keys(items).length

	useCartNotification(() => setCartWidgetVisible(true))

	useEffect(() => {
		const interval = setInterval(() => {
			if (scrollY.current) {
				const delta = scrollY.current - window.scrollY
				if (delta > 50 && !headerVisible) {
					setHeaderVisible(true)
				} else if (delta < -50 && headerVisible) {
					if (window.scrollY > 500) {
						setHeaderVisible(false)
					}
				}
			}

			scrollY.current = window.scrollY
		}, 250)

		return () => {
			clearInterval(interval)
		}
	}, [headerVisible])

	return (
		<div>
			{title && (
				<Head>
					<title>{title} | Edomo</title>
				</Head>
			)}
			<CartWidget visible={cartWidgetVisible} hide={() => setCartWidgetVisible(false)} />
			<div className={styles.wrapper} style={{ top: headerVisible ? 0 : -176 }}>
				<header className={styles.container}>
					{screen.md && (
						<BackgroundWrapper color='#1f1f1f' className={styles.shippingInfo}>
							<Row align='middle' justify='center'>
								<img
									src='/images/icons/free-shipping.svg'
									width={32}
									height={32}
									className={styles.shippingIcon}
								/>
								<Text className='grey-1'>Wir versenden kostenlos deutschlandweit</Text>
							</Row>
						</BackgroundWrapper>
					)}
					<Row align='middle' justify='space-between' className={styles.header}>
						<Col span={0} md={8}>
							<Row align='middle' className={styles.pages}>
								<Link href='/'>
									<a>
										<Text className='semibold-16 grey-8'>Start</Text>
									</a>
								</Link>
								<Link href='/ueber-uns'>
									<a>
										<Text className='semibold-16 grey-8'>Über uns</Text>
									</a>
								</Link>
								<Link href='/blog'>
									<a>
										<Text className='semibold-16 grey-8'>Blog</Text>
									</a>
								</Link>
							</Row>
						</Col>
						<Col>
							<Link href='/'>
								<a>
									<img src='/edomo-logo.svg' className={styles.logo} />
								</a>
							</Link>
						</Col>
						<Col span={8}>
							<Row align='middle' justify='end' className={styles.right}>
								{!screen.lg && screen.md && (
									<Col flex={1} className={styles.searchbox}>
										<Row justify='end'>
											<SearchBox />
										</Row>
									</Col>
								)}
								<Col className={styles.cartButton}>
									<a onClick={() => setCartWidgetVisible(true)}>
										<ShoppingCartOutlined className={styles.icon} />
										<Badge
											count={noOfItems}
											showZero={false}
											size='small'
											offset={[-10, -20]}
											className={styles.badge}
										/>
									</a>
								</Col>
							</Row>
						</Col>
					</Row>
					<Navigation />
				</header>
			</div>
			{!screen.md && <Spacer size={104} />}
		</div>
	)
}

export default Header
