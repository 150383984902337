import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { Category } from 'services/categories'
import { Col, Row, Typography } from 'antd'
import BackgroundWrapper from 'components/background-wrapper'
import styles from './sub-menu.module.less'

const { Text } = Typography

interface Props {
	category?: Category
}

const SubMenu = ({ category }: Props) => {
	const [activeCategory, setActiveCategory] = useState<Category>()
	const [visible, setVisible] = useState(false)
	const [isHovering, setIsHovering] = useState(false)

	const timeout = useRef<NodeJS.Timeout>()

	useEffect(() => {
		if (category) {
			if (timeout.current) {
				clearTimeout(timeout.current)
			}
			setActiveCategory(category)
			setVisible(true)
		} else if (!isHovering) {
			setVisible(false)
			timeout.current = setTimeout(() => {
				setActiveCategory(undefined)
			}, 2000)
		}
	}, [category, isHovering])

	return (
		<div
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			className={styles.wrapper}
			style={{ top: visible ? 0 : -300 }}
		>
			<BackgroundWrapper className={styles.container}>
				<Row className={styles.content} gutter={48}>
					{activeCategory?.image && (
						<Col>
							<img
								src={activeCategory.image.url}
								width={180}
								height={180}
								className={styles.image}
							/>
							<Link href={`/kategorie/${activeCategory.handle}`}>
								<a>
									<div className={styles.imageOverlay}>
										<Text className='semibold-16'>{activeCategory.name}</Text>
									</div>
								</a>
							</Link>
						</Col>
					)}
					<Col className={styles.menu}>
						{activeCategory?.items?.map(sub => (
							<div key={sub.handle} className={styles.sub}>
								<Link href={`/kategorie/${activeCategory.handle}/${sub.handle}`}>
									<a>
										<Text className='semibold-16'>{sub.name}</Text>
									</a>
								</Link>
								{sub.items?.map(subsub => (
									<div key={subsub.handle} className={styles.subsub}>
										<Link
											href={`/kategorie/${activeCategory.handle}/${sub.handle}/${subsub.handle}`}
										>
											<a>
												<Text className='regular-14'>{subsub.name}</Text>
											</a>
										</Link>
									</div>
								))}
							</div>
						))}
					</Col>
				</Row>
			</BackgroundWrapper>
		</div>
	)
}

export default SubMenu
