import data from 'etc/categories.json'

export interface Category {
	handle: string
	name: string
	image?: {
		url: string
	}
	icon?: {
		url: string
	}
	banner?: {
		title?: string
		content: string
		light: boolean
		accentColor: string
		backgroundColor: string
		image: {
			url: string
		}
		overlay?: {
			url: string
		}
		icon?: {
			url: string
		}
	}
	items?: Category[]
}

export const getCategories = (): Category[] => {
	return data.categories as unknown as Category[]
}

export const getCategory = (handle: string) => {
	function find(categories: Category[], handle: string): Category | undefined {
		for (let category of categories) {
			if (category.handle === handle) return category
			if (category.items) {
				const c = find(category.items, handle)
				if (c) return c
			}
		}
	}

	return find(data.categories as unknown as Category[], handle)
}
