import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { formatPriceString } from 'services/price'
import { Col, notification, Row, Typography } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import styles from './useCartNotification.module.less'

const { Text } = Typography

const useCartNotification = (onClick: () => void) => {
	const rehydrated = useSelector((state: RootState) => state._persist.rehydrated)
	const action = useSelector((state: RootState) => state.cart.lastAction)
	const items = useSelector((state: RootState) => state.cart.items)

	const lastRehydrated = useRef(false)

	useEffect(() => {
		if (action?.type === 'ADD' && lastRehydrated.current) {
			if ((action.timestamp ?? 0) + 1000 < Date.now()) return

			const { variant, title, quantity } = items[action.itemId]

			const price = parseFloat(variant.priceV2.amount) * quantity

			notification.open({
				message: (
					<a>
						<Text className='grey-8'>
							<CheckCircleFilled className={styles.check} /> Dein Produkt wurde hinzugefügt!
						</Text>
					</a>
				),
				description: (
					<a>
						<Row className={styles.notification} gutter={16}>
							<Col>
								{/* @ts-ignore */}
								<img src={variant.image?.thumbnail as string} width={72} height={96} />
							</Col>
							<Col flex={1}>
								<Row justify='space-between'>
									<Text className='grey-9'>{title}</Text>
									<Text className='semibold-14 grey-9'>{formatPriceString(price)}</Text>
								</Row>
								<div>
									<Text className='regular-12 grey-8'>
										Einzelpreis: {formatPriceString(variant.priceV2.amount)}
									</Text>
								</div>
								<div>
									<Text className='regular-12 grey-8'>Menge: {quantity}</Text>
								</div>
							</Col>
						</Row>
					</a>
				),
				duration: 4.7,
				onClick: () => {
					notification.destroy()
					onClick()
				}
			})
		}
		lastRehydrated.current = rehydrated
	}, [action, rehydrated])
}

export default useCartNotification
