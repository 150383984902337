import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { getEstimatedDelivery } from 'services/delivery'
import { formatPriceString } from 'services/price'
import { checkMinimumOrderValue } from 'services/cart'
import { Vendor } from 'etc/strapi-types'
import { Col, Row, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import styles from './vendor-info.module.less'

const { Text } = Typography

interface Props {
	vendor: string
	vendors: Pick<Vendor, 'slug' | 'name' | 'minimumOrderValue'>[]
	size?: 'small' | 'medium'
}

const VendorInfo = ({ vendor, vendors, size = 'medium' }: Props) => {
	const items = useSelector((state: RootState) => state.cart.items)

	let name = vendors.find(({ slug }) => slug === vendor)?.name

	// FIX regarding Traupe
	if (name?.match(/beckers bester|Schierker Feuerstein|Einbecker Brauhaus/g)) {
		name = 'Getränke Traupe Northeim'
	}

	const filtered = Object.values(items).filter(item => item.vendor === vendor)
	const deliveryDate = getEstimatedDelivery(filtered.map(item => item.estimated_delivery))

	const minimumOrderValue = vendors.find(v => v.slug === vendor)?.minimumOrderValue ?? 0

	const { minimumReached } = checkMinimumOrderValue(minimumOrderValue, filtered)

	return (
		<Row
			justify='space-between'
			className={`${styles.container} ${size === 'medium' && styles.medium} ${
				!minimumReached && (size === 'medium' ? styles.alert : styles.alertBg)
			}`}
			gutter={[8, 4]}
		>
			<Col flex={size === 'small' ? 1 : undefined}>
				<div className={size === 'small' ? styles.row : undefined}>
					<Text className='grey-7'>Versand durch: </Text>
					<Text className='grey-9'>{name}</Text>
				</div>
				<div className={styles.spacer} />
				<div className={size === 'small' ? styles.row : undefined}>
					<Text className='grey-7'>Voraussichtliche Lieferung: </Text>
					<Text className='grey-9'>{deliveryDate}</Text>
				</div>
			</Col>
			{size === 'medium' && (
				<Col>
					<Text className='grey-7'>Mindestbestellwert: </Text>
					<Text className={minimumReached ? styles.minimumReached : styles.minimumNotReached}>
						{formatPriceString(minimumOrderValue)}
					</Text>
					{minimumReached ? (
						<CheckCircleOutlined className={`${styles.minimumReached} ${styles.icon}`} />
					) : (
						<CloseCircleOutlined className={`${styles.minimumNotReached} ${styles.icon}`} />
					)}
				</Col>
			)}
		</Row>
	)
}

export default VendorInfo
