import { Col, Grid, Row, Typography } from 'antd'
import BackgroundWrapper from 'components/background-wrapper'
import Spacer from 'components/spacer'
import styles from './values.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

const Values = () => {
	const screen = useBreakpoint()

	return (
		<BackgroundWrapper color='#f5f5f5'>
			<Spacer size={48} />
			<Row justify='center' gutter={screen.md ? [32, 32] : [16, 16]} className={styles.cards}>
				<Col span={24} md={12} lg={6}>
					<div className={styles.card}>
						<Row align='bottom' gutter={16}>
							<Col>
								<img src='/images/icons/thumbs-up.svg' width={32} height={32} />
							</Col>
							<Col>
								<Text className={styles.cardTitle}>Echte Qualität</Text>
							</Col>
						</Row>
						<Spacer size={8} />
						<Text className='grey-8'>
							Die Qualität unserer Produkte beginnt mit dem ersten Handschlag und führt zu
							beständiger Freude am Produkt. Man schmeckt, riecht und fühlt, dass wir unser Handwerk
							beherrschen.
						</Text>
					</div>
				</Col>
				<Col span={24} md={12} lg={6}>
					<div className={styles.card}>
						<Row align='bottom' gutter={16}>
							<Col>
								<img src='/images/icons/handshake-heart.svg' width={32} height={32} />
							</Col>
							<Col>
								<Text className={styles.cardTitle}>Echte Gemeinschaft</Text>
							</Col>
						</Row>
						<Spacer size={8} />
						<Text className='grey-8'>
							Jeder hilft jedem: Wir packen an, wenn jemand Hilfe braucht, vertrauen uns und treiben
							uns an. Und weil wir die Reise zusammen antreten, bleibt bei uns niemand auf der
							Strecke.
						</Text>
					</div>
				</Col>
				<Col span={24} md={12} lg={6}>
					<div className={styles.card}>
						<Row align='bottom' gutter={16}>
							<Col>
								<img src='/images/icons/work.svg' width={32} height={32} />
							</Col>
							<Col>
								<Text className={styles.cardTitle}>Echtes Handwerk</Text>
							</Col>
						</Row>
						<Spacer size={8} />
						<Text className='grey-8'>
							Jeder von uns ist Profi in dem, was er tut. Manufaktur bedeutet für uns, bewährte
							Rezepte und Techniken mit eigenen Händen umzusetzen. Die eine Hand weiß eben, was die
							andere tut.
						</Text>
					</div>
				</Col>
				<Col span={24} md={12} lg={6}>
					<div className={styles.card}>
						<Row align='bottom' gutter={16}>
							<Col>
								<img src='/images/icons/environmental-planning.svg' width={32} height={32} />
							</Col>
							<Col>
								<Text className={styles.cardTitle}>Echte Nachhaltigkeit</Text>
							</Col>
						</Row>
						<Spacer size={8} />
						<Text className='grey-8'>
							“Made in Germany” bedeutet für uns kurze Transportwege. Und weil viele unserer
							Produkte für die Ewigkeit gemacht sind, haben wir auch die Müllproduktion im Auge.
						</Text>
					</div>
				</Col>
			</Row>
			<Spacer size={48} />
		</BackgroundWrapper>
	)
}

export default Values
