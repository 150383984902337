import { ReactNode } from 'react'
import Header from './header'
import Footer from './footer'
import styles from './page.module.less'

interface Props {
	children: ReactNode
	title?: string
	footer?: 'slim'
}

const Page = ({ children, title, footer }: Props) => (
	<div className={styles.container}>
		<Header title={title} />
		<main className={styles.content}>{children}</main>
		<Footer slim={footer === 'slim'} />
	</div>
)

export default Page
