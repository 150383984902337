import { useState } from 'react'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { editQuantity, removeItem, editNote } from 'store/cart/actions'
import { CartItem } from 'store/cart/types'
import { formatPriceString } from 'services/price'
import { Button, Col, Input, Row, Typography } from 'antd'
import { CloseOutlined, SnippetsOutlined } from '@ant-design/icons'
import Quantity from 'components/product/quantity'
import styles from './item.module.less'

const { Text } = Typography

interface Props {
	item: CartItem
	size?: 'small' | 'medium'
}

const Item = ({ item, size = 'medium' }: Props) => {
	const dispatch = useDispatch()

	const [editingNote, setEditingNote] = useState(false)
	const [noteValue, setNoteValue] = useState<string>()

	const { id, variant, quantity, note } = item
	const { priceV2, image, selectedOptions } = variant

	return (
		<Row
			justify='space-between'
			gutter={{ xs: 12, sm: 12, md: size === 'medium' ? 48 : 12 }}
			className={styles.row}
		>
			<Col>
				<Link href={`/produkt/${item.handle}`}>
					<a>
						<img
							// @ts-ignore
							src={image?.thumbnail ?? image?.transformedSrc}
							width={size === 'medium' ? 128 : 57}
							height={size === 'medium' ? 160 : 76}
						/>
					</a>
				</Link>
			</Col>
			<Col className={styles.info} flex={1}>
				<div>
					<Link href={`/produkt/${item.handle}`}>
						<a>
							<Text className={`semibold-${size === 'medium' ? 16 : 14}`}>{item.title}</Text>
						</a>
					</Link>
					{size === 'small' && (
						<div>
							<Text className='regular-12 grey-8'>Menge: {quantity}</Text>
						</div>
					)}
					{size === 'medium' && (
						<div>
							<Text className='regular-12 grey-8'>
								Einzelpreis: {formatPriceString(item.variant.priceV2.amount)}
							</Text>
							{selectedOptions
								.filter(option => option.value !== 'Default Title')
								.map(option => (
									<div key={option.name}>
										<Text className='regular-12 grey-8'>
											{option.name}: {option.value}
										</Text>
									</div>
								))}
						</div>
					)}
				</div>
				<div>
					{(note || editingNote) && (
						<div className={styles.note}>
							{editingNote ? (
								<div>
									<Input.TextArea
										value={noteValue}
										onChange={e => setNoteValue(e.target.value)}
										className='regular-12 grey-7'
									/>
									<Row justify='end' className={styles.noteButtons}>
										<Button
											size='small'
											type='text'
											className='regular-12 grey-7'
											onClick={() => setEditingNote(false)}
										>
											Abbrechen
										</Button>
										<Button
											size='small'
											className='regular-12 grey-7'
											onClick={() => {
												dispatch(editNote(id, noteValue))
												setEditingNote(false)
											}}
										>
											Speichern
										</Button>
									</Row>
								</div>
							) : (
								<Text className='regular-12 grey-7'>
									Bestellhinweis:
									<br />
									{note}
								</Text>
							)}
						</div>
					)}
					<Row gutter={size === 'medium' ? 32 : 24}>
						<Col>
							<div onClick={() => dispatch(removeItem(item.id))}>
								<a>
									<Text className={`regular-${size === 'medium' ? 12 : 10} grey-7`}>
										<CloseOutlined /> Entfernen
									</Text>
								</a>
							</div>
						</Col>
						{size === 'medium' && (
							<Col>
								<div
									onClick={() => {
										setEditingNote(true)
										setNoteValue(note)
									}}
								>
									<a>
										<Text className='regular-12 grey-7'>
											<SnippetsOutlined /> Bestellhinweis {note ? 'bearbeiten' : 'hinzufügen'}
										</Text>
									</a>
								</div>
							</Col>
						)}
					</Row>
				</div>
			</Col>
			<Col>
				<div className={styles.price}>
					<Text className={`semibold-${size === 'medium' ? 16 : 14} grey-9`}>
						{formatPriceString(parseFloat(priceV2.amount) * item.quantity)}
					</Text>
				</div>
				{size === 'medium' && (
					<Quantity
						onChange={value => dispatch(editQuantity(item.id, value))}
						quantity={item.quantity}
						variant={item.variant}
						mode='cart'
						className={styles.quantity}
					/>
				)}
			</Col>
		</Row>
	)
}

export default Item
