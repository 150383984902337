import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { ProductVariant } from 'etc/storefront-types'
import { InputNumber, Typography } from 'antd'
import styles from './quantity.module.less'

const { Text } = Typography

interface Props {
	mode?: 'product' | 'cart'
	variant: ProductVariant
	quantity: number
	onChange: (quantity: number) => void
	className?: string
}

const Quantity = ({ mode = 'product', variant, quantity, onChange, className }: Props) => {
	const quantityInCart = useSelector(
		(state: RootState) => state.cart.items[variant.id]?.quantity || 0
	)

	let maxAvailable = 0

	if (variant.availableForSale) {
		if (!variant.quantityAvailable || variant.quantityAvailable <= 0) {
			maxAvailable = Infinity
		} else if (variant.quantityAvailable) {
			maxAvailable = variant.quantityAvailable
			if (mode === 'product') {
				maxAvailable -= quantityInCart
			}
		}
	}

	return (
		<div className={styles.container}>
			<InputNumber
				min={1}
				max={maxAvailable}
				precision={0}
				disabled={maxAvailable === 0}
				value={quantity}
				onChange={value => onChange(value as number)}
				size='small'
				className={`${styles.input} ${className}`}
			/>
			{quantity > 0 && quantity === maxAvailable && (
				<Text className={`regular-12 grey-8 ${styles.text}`}>
					Nur noch {maxAvailable} verfügbar
				</Text>
			)}
			{maxAvailable === 0 && (
				<Text className={`regular-12 grey-8 ${styles.text}`}>Keine weiteren Artikel verügbar</Text>
			)}
		</div>
	)
}

export default Quantity
