import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Row, Typography, Menu, Col } from 'antd'
import { CloseSquareOutlined } from '@ant-design/icons'
import { getCategories } from 'services/categories'
import styles from './mobile-menu.module.less'

const { SubMenu } = Menu
const { Text } = Typography

interface Props {
	visible: boolean
	hide: () => void
}

const MobileMenu = ({ visible, hide }: Props) => {
	const [openKeys, setOpenKeys] = useState<string[]>([])
	const [lastClicked, setLastClicked] = useState<string>()

	const categories = getCategories()
	const rootSubmenuKeys = categories.map(item => item.handle)

	const router = useRouter()

	useEffect(() => {
		document.body.style.overflowY = visible ? 'hidden' : 'scroll'
	}, [visible])

	const onOpenChange = (keys: string[]) => {
		const latest = keys.find(key => openKeys.indexOf(key) === -1)
		if (latest && !rootSubmenuKeys.includes(latest)) {
			setOpenKeys(keys)
		} else {
			setOpenKeys(latest ? [latest] : [])
		}
	}

	const navigate = (route: string) => {
		router.push(route)
		hide()
		setLastClicked(undefined)
	}

	return (
		<div onClick={hide} className={`${styles.wrapper} ${visible && styles.visible}`}>
			<div
				className={`${styles.container} ${visible && styles.containerVisible}`}
				onClick={e => e.stopPropagation()}
			>
				<Row justify='space-between' align='middle' className={styles.title}>
					<Col>
						<Text className='semibold-24 grey-9'>Menü</Text>
					</Col>
					<Col>
						<a onClick={hide}>
							<CloseSquareOutlined className={styles.close} />
						</a>
					</Col>
				</Row>
				<div className={styles.menu}>
					<div>
						<Menu mode='inline' openKeys={openKeys} onOpenChange={onOpenChange as any}>
							{categories.map(category => (
								<SubMenu
									key={category.handle}
									title={category.name}
									onTitleClick={() => {
										if (lastClicked === category.handle) {
											navigate(`/kategorie/${category.handle}`)
										} else {
											setLastClicked(category.handle)
										}
									}}
								>
									{category.items?.map(sub =>
										sub.items ? (
											<SubMenu
												key={sub.handle}
												title={sub.name}
												onTitleClick={() => {
													if (lastClicked === sub.handle) {
														navigate(`/kategorie/${category.handle}/${sub.handle}`)
													} else {
														setLastClicked(sub.handle)
													}
												}}
											>
												{sub.items.map(item => (
													<Menu.Item
														key={item.handle}
														onClick={() =>
															navigate(`/kategorie/${category.handle}/${sub.handle}/${item.handle}`)
														}
													>
														{item.name}
													</Menu.Item>
												))}
											</SubMenu>
										) : (
											<Menu.Item
												key={sub.handle}
												onClick={() => navigate(`/kategorie/${category.handle}/${sub.handle}`)}
											>
												{sub.name}
											</Menu.Item>
										)
									)}
								</SubMenu>
							))}
							<div className={styles.divider} />
							<Menu.Item onClick={() => navigate('/')}>Start</Menu.Item>
							<Menu.Item onClick={() => navigate('/blog')}>Blog</Menu.Item>
							<Menu.Item onClick={() => navigate('/ueber-uns')}>Über Uns</Menu.Item>
							<Menu.Item onClick={() => navigate('/faq')}>Häufige Fragen</Menu.Item>
							<div className={styles.divider} />
							<Menu.Item onClick={() => navigate('/faq/versand-und-lieferung')}>
								Versand & Lieferung
							</Menu.Item>
							<Menu.Item onClick={() => navigate('/widerruf')}>Widerrufsbelehrung</Menu.Item>
							<Menu.Item onClick={() => navigate('/agb')}>AGB</Menu.Item>
							<Menu.Item onClick={() => navigate('/datenschutz')}>Datenschutz</Menu.Item>
							<Menu.Item onClick={() => navigate('/impressum')}>Impressum</Menu.Item>
							<div className={styles.divider} />
						</Menu>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileMenu
